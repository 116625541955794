h1{
  margin: 0 !important;
  padding: 0 !important;
}
.btntop{
  display: flex;
  flex-direction: row;
  margin-bottom: 30px;
}
.btn-top{
  margin: 3px;
  width: 100px;
  height: 100px;
}
.infoAuno{
  border: 2px solid #f7f7f7;
  border-radius: 10px;
  padding: 10px;
  margin-bottom: 20px;
}
.notfound{
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100px;
  background: #f8f8f8;
  border-radius: 10px;
  margin-bottom: 10px;
}
.info-bloco{
  margin-bottom: 10px;
  border: 2px solid #f9f9f9;
  border-radius: 10px;
  padding: 8px;
}
.info-bloco-header{
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 10px;
  font-weight: 700;
  font-size: 18px;
}
.info-bloco-body{
  margin-bottom: 10px;
}
.info-registro-frequencias{
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: row;
  flex-wrap: wrap;
}
.info-registro-frequencias div{
  margin: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-radius: 10px;
  padding: 6px;
}
.info-bloco-resumo-frequencia{
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 20px;
  font-size: 16px;
  font-weight: 700;
}
.info-registro-relatorio-final{
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
}
.info-registro-relatorio-final div{
  margin: 3px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  width: 100%;
}