.root {
  display: flex;
  background: rgb(245, 248, 255);
}
.content{
  flex-grow: 1;
  height: 100vh;
  overflow: auto;
}
.appBarSpacer{
  padding-top: 40px;
}
.container{
  padding-top: 40px;
  padding-bottom: 40px;

}
.imgMenu{
  margin-top: 20px;
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.imgMenu img{
  max-width: 50%;
}
.paper{
  position: relative;
  box-shadow: none !important;
  padding: 10px;
  display: flex;
  overflow: hidden;
  flex-direction: column;
  min-height: 200px;
}
.paperSearch{
  box-shadow: none !important;
  display: flex;
  overflow: auto;
  flex-direction: column;
  background: #e5e5e5 !important;
  overflow: hidden;
  padding: 10px;
}
.paperHeader{
  margin-bottom: 10px;
  color: rgb(100, 114, 140);
}
.paperContant{
  width: 100%;
}

.paperContantDois{
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
  background-color: rgb(240, 239, 239);
}

.paperContantTres{
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content:center;
  align-items: center;
  flex-direction: column;
  cursor: pointer;
}

.paperContantTres:hover{
  background: #e5e6e6;
}

.paperUm{
  text-align: center;
  color: rgb(100, 114, 140);
  font-weight: bold;
}

.textField{
  background: #FFFF;
}

.headerPage{
  display: flex;
  justify-content: space-between;
  justify-items: center;
}
.headerPage h2{
  font-size: calc(0.9em + 0.9vw);
  margin: 0px
}
.button-add{
  font-size: calc(0.6em + 0.6vw);
  background:rgb(63, 1, 121) !important;
  border: none !important;
}
.linkBreadcrumbs{
  font-size: 12px;
}

/* skeleton */
.skelTitlePage{
  width: 30%;
  height: 40px !important;
}

/* table */
.table{
  overflow: hidden;
}
.tableRow{
  border: none !important;
  margin-bottom: 10px !important;
  height: 80px;
}
.tableCell{
  border: none;
}
.loading{
  position: absolute;
  z-index: 999999;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.infoFatura{
  display: flex;
  justify-content:flex-start;
  align-items:flex-start;
  flex-direction: column;
}
.itemConfig{
  position: absolute;
  min-height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}
.itemConfigDois{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 10px;
  padding: 20px;
}
.itemConfigDois:hover{
  cursor: pointer;
  background-color: #e5e5e5;
  border-radius: 10px;
}
.listItemIcon{
  border: #e5e5e5 1px solid;
  border-radius: 5px;
  min-width: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  margin: 5px;
  padding-top: 5px;
  padding-bottom: 5px;
}
.listItemIcon:hover{
  background-color: #e5e5e5;
  cursor: pointer;
}
.error404{
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.customizedInput{
  width: 100%;
  border: #afaeae solid 1px;
  height: 55px;
  padding: 10px;
  font-size: 18px;
  border-radius: 5px;
}

/* configurações do diario */
.diarioHeader{
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 20px;
}
.diarioHeader .ih{
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgb(226, 241, 252);
  margin: 5px;
}
.diarioHeader .ih span{
  padding: 5px;
}
.diarioItem{
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
}
.diarioItem .it{
  width: 200px;
  height: 200px;
  border-radius: inherit;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgb(255, 248, 228);
  margin: 5px;
  font-size: 16px;
}
.diarioItem .it:hover{
  background: rgb(224, 218, 202);
  cursor: pointer;
}

.modal{
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.modalContent{
  width: 80%;
  height: 95%;
  background: white;
  overflow-x: hidden;
}
.modalContentHeader{
  background: #f7f7f7;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.modalContentCenter{
  padding: 10px;
}
.notFound{
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  height: 100px;
  width: 100%;
}